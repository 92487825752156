import React, { useRef } from 'react';

import { Button, Input, Popconfirm, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

import { TicketListItem } from './ticket.interface';
import OrderStatus from './ticket-status.component';

const Product: React.FC<{
    dataSource?: TicketListItem[];
    isLoading?: boolean;
    isActionDisabled?: boolean;
    onClickDetail?: (data: TicketListItem) => void;
    onChange?: (pagination: any, query: { [x: string]: any }) => void;
}> = ({ dataSource = [], isLoading, isActionDisabled, onClickDetail, onChange }) => {
    const searchInput = useRef<any>();

    const handleSearch = (_: any, confirm: any, __: any) => {
        confirm();
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value ? e.target.value.trim() : null)}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current!.select());
            }
        },
    });

    const COLUMNS = [
        {
            title: 'Serinal Number',
            dataIndex: 'productId',
            fixed: 'left' as any,
            render: (text: string, record: TicketListItem) => (
                <Button
                    style={{ padding: 0 }}
                    type='link'
                    disabled={isActionDisabled}
                    onClick={() => onClickDetail && onClickDetail(record)}
                >
                    {text}
                </Button>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: string) => <OrderStatus status={status} />,
        },
        {
            title: 'Company Name',
            dataIndex: 'customerName',
        },
    ];

    return (
        <Table
            rowKey='id'
            pagination={false}
            onChange={onChange}
            loading={isLoading}
            columns={COLUMNS}
            dataSource={dataSource}
        />
    );
};

export default Product;
