import h from 'src/app/helper/page/header';

export default h({
    breadcrumbs: [
        {
            path: '/ticket',
            label: 'Case',
        },
    ],
});
