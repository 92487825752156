import { useCallback, useRef, useState, useEffect } from 'react';

import { Form } from 'antd';
import { stringify } from 'qs';

import { useApi } from 'src/core/network/lib/useApi';

import dateFormatter from 'src/app/helper/format/date';

import { CreateTicket, TicketListItem } from './ticket.interface';

export enum TicketCreateStep {
    INPUT = 0,
    END = 2,
}

export default () => {
    const { get } = useApi('support/hello');
    const {
        loading: ticketReadManyIsLoading,
        data: ticketReadManyData,
        cache: ticketReadManyCache,
        get: ticketReadMany,
    } = useApi('support/tickets');
    const { data: ticketReadOneData, get: ticketReadOne, abort: ticketReadOneAbort } = useApi(
        'support/tickets'
    );
    const {
        loading: ticketCreateOneIsLoading,
        data: ticketCreateOneData,
        cache: ticketCreateOneCache,
        post: ticketCreateOne,
    } = useApi('support/tickets');
    const { loading: ticketUpdateOneIsLoading, put: ticketUpdateOne } = useApi('support/tickets');
    const { loading: ticketDeleteOneIsLoading, del: ticketDeleteOne } = useApi('support/tickets');
    const {
        data: ticketReadProductTimelineData,
        loading: ticketReadProductTimelineIsLoading,
        get: ticketReadProductTimeline,
    } = useApi('support/tickets');

    const [ticketCreateForm] = Form.useForm();
    const [ticketUpdateForm] = Form.useForm();

    const [ticketDetailData, ticketDetailSetData] = useState<TicketListItem>();

    const [
        ticketDetailProductTimelineIsVisible,
        ticketDetailProductTimelineSetIsVisible,
    ] = useState<boolean>();
    const [ticketDetailContactIsVisible, ticketDetailContactSetIsVisible] = useState<boolean>();
    const [orderUpdateData, orderUpdateSetData] = useState<TicketListItem>();

    const [ticketUpdateFormIsVisible, ticketUpdateFormSetIsVisible] = useState(false);
    const [ticketCreateFormIsVisible, ticketCreateFormSetIsVisible] = useState(false);
    const [ticketCreateFormStep, ticketCreateFormSetStep] = useState(TicketCreateStep.INPUT);

    const ticketCreateFormHasCreatedOneSinceLastOpen = useRef<boolean>();
    const ticketUpdateFormHasUpdatedOneSinceLastOpen = useRef<boolean>();

    const onMenuCreateOrderClick = useCallback(() => {
        ticketCreateFormSetIsVisible(true);
    }, [ticketCreateFormSetIsVisible]);

    const onTicketCreateFormFullyClosed = useCallback(
        (visible: boolean) => {
            if (!visible) {
                if (ticketCreateFormHasCreatedOneSinceLastOpen.current) {
                    ticketReadManyCache.clear();
                    ticketReadMany();
                }
                ticketCreateFormHasCreatedOneSinceLastOpen.current = false;
                ticketCreateFormSetStep(TicketCreateStep.INPUT);
            }
        },
        [ticketReadManyCache, ticketReadMany, ticketCreateFormSetStep]
    );

    const onTicketCreateFormHide = useCallback(() => {
        ticketCreateFormSetIsVisible(false);
    }, [ticketCreateFormSetIsVisible]);
    const onTicketCreateFormInputSubmit = useCallback(() => ticketCreateForm.submit(), [
        ticketCreateForm,
    ]);
    const onTicketCreateFormInputFinish = useCallback(() => {
        ticketCreateForm.validateFields().then((store) => {
            const values = store as CreateTicket;
            ticketCreateOne({
                id: values.ticketIdSwitch
                    ? `${values.ticketIdPrefix}-${values.ticketId}`
                    : undefined,
                customerCode: values.customerCode,
                customerName: values.customerName,
                customerNote: values.customerNote,
                productControl: values.productControl,
                productCategory: values.productCategory,
                productId: values.productId,
                productWarrantyStatus: values.productWarrantyStatus,
                inspectorCode: values.inspectorCode,
                inspectorName: values.inspectorName,
                inspectionNote: values.inspectionNote,
                inspectionFinishedAt: values.inspectionFinishedAtSwitch
                    ? values.inspectionFinishedAt
                    : undefined,
                createdAt: values.createdAt,
            }).then(({ error }) => {
                if (error) return;
                ticketCreateForm.resetFields();
                ticketCreateFormHasCreatedOneSinceLastOpen.current = true;
                ticketCreateFormSetStep(TicketCreateStep.END);
            });
        });
    }, [ticketCreateForm, ticketCreateOne]);

    const onTicketCreateFormEndAnother = useCallback(() => {
        ticketCreateFormSetStep(TicketCreateStep.INPUT);
    }, []);
    const onTicketCreateFormEndFinish = useCallback(() => {
        ticketCreateFormSetIsVisible(false);
    }, []);

    const onTicketTableSearch = useCallback(
        (_, query: { [x: string]: any }) => {
            ticketReadMany(stringify(query, { skipNulls: true, addQueryPrefix: true }));
        },
        [ticketReadMany]
    );

    const onTicketTableDetailClick = useCallback(
        (listItem: TicketListItem) => {
            ticketDetailSetData(listItem);
            ticketReadOneAbort();
            ticketReadOne(`/${listItem.id}`);
        },
        [ticketReadOneAbort, ticketReadOne]
    );

    const onTicketDetailHide = useCallback(() => {
        ticketDetailSetData(undefined);
    }, []);

    const onTicketTableItemUpdateClick = useCallback(
        (listItem: TicketListItem) => {
            ticketUpdateFormSetIsVisible(true);
            ticketUpdateForm.setFieldsValue({
                ...listItem,
                createdAt: dateFormatter(listItem.createdAt),
                inspectionFinishedAt: listItem.inspectionFinishedAt
                    ? dateFormatter(listItem.inspectionFinishedAt)
                    : undefined,
                ticketIdPrefix: listItem.id.split('-')[0],
                ticketId: listItem.id.split('-').slice(1).join('-'),
            });
            orderUpdateSetData(listItem);
        },
        [ticketUpdateForm]
    );

    const onTicketUpdateFormFullyClosed = useCallback(
        (visible: boolean) => {
            if (!visible) {
                if (ticketUpdateFormHasUpdatedOneSinceLastOpen.current) {
                    ticketReadManyCache.clear();
                    ticketCreateOneCache.clear();
                    ticketReadMany();
                }
                ticketUpdateFormHasUpdatedOneSinceLastOpen.current = false;
            }
        },
        [ticketReadManyCache, ticketReadMany]
    );
    const onTicketUpdateFormFinish = useCallback(() => {
        ticketUpdateForm.validateFields().then((values) => {
            ticketUpdateOne(`${values.ticketIdPrefix}-${values.ticketId}`, {
                inspectorCode: values.inspectorCode,
                inspectorName: values.inspectorName,
                inspectionNote: values.inspectionNote,
                inspectionCenterCode: values.inspectionCenterCode,
                inspectionCenterName: values.inspectionCenterName,
                inspectionFinishedAt: values.inspectionFinishedAtSwitch
                    ? values.inspectionFinishedAt
                    : undefined,
            }).then(({ error }) => {
                if (error) return;
                ticketUpdateForm.resetFields();
                ticketUpdateFormHasUpdatedOneSinceLastOpen.current = true;
                ticketUpdateFormSetIsVisible(false);
            });
        });
    }, [orderUpdateData]);
    const onTicketUpdateFormSubmit = useCallback(() => {
        ticketUpdateForm.submit();
    }, [ticketUpdateForm]);

    const onTicketUpdateFormHide = useCallback(() => {
        ticketUpdateForm.resetFields();
        ticketUpdateFormSetIsVisible(false);
    }, [ticketUpdateForm]);

    const onTicketTableRefresh = useCallback(() => {
        ticketReadManyCache.clear();
        ticketReadMany();
    }, [ticketReadManyCache, ticketReadMany]);

    const onTicketTableItemDelete = useCallback(
        (data: TicketListItem) => {
            ticketDeleteOne(`/${data.id}`).then(() => {
                ticketReadManyCache.clear();
                ticketReadMany();
            });
        },
        [ticketDeleteOne, ticketReadManyCache, ticketReadMany]
    );

    const onTicketDetailContactClick = useCallback(
        (data: any) => {
            ticketDetailContactSetIsVisible(true);
        },
        [ticketDetailContactSetIsVisible]
    );

    const onTicketDetailContactDetailHide = useCallback(
        (data: any) => {
            ticketDetailContactSetIsVisible(false);
        },
        [ticketDetailContactSetIsVisible]
    );

    const onTicketDetailProductClick = useCallback((data: any) => {
        ticketDetailProductTimelineSetIsVisible(true);
        ticketReadProductTimeline(`${data.id}/related`);
    }, []);

    const onTicketDetailProductTimelineHide = useCallback(() => {
        ticketDetailProductTimelineSetIsVisible(false);
    }, [ticketDetailProductTimelineSetIsVisible]);

    useEffect(() => {
        get();
        ticketReadMany();
    }, [ticketReadMany]);

    return {
        ticketTablePayload: ticketReadManyData?.payload || [],
        ticketTableIsLoading: ticketReadManyIsLoading || ticketDeleteOneIsLoading,
        ticketTableActionIsDisabled: ticketCreateOneIsLoading || ticketReadManyIsLoading,
        ticketDetailContactIsVisible,
        ticketDetailProductTimelineIsLoading: ticketReadProductTimelineIsLoading,
        ticketDetailProductTimelinePayload: ticketReadProductTimelineData?.payload,
        ticketDetailProductTimelineIsVisible,
        ticketDetailPayload: ticketDetailData,
        ticketDetailIsVisible: !!ticketDetailData,
        ticketCreateForm,
        ticketCreateFormStep,
        ticketCreateFormInputIsLoading: ticketCreateOneIsLoading,
        ticketCreateFormEndPayload: ticketCreateOneData?.payload || {},
        ticketCreateFormIsVisible,
        ticketCreateFormPreviewIsLoading: ticketCreateOneIsLoading,
        ticketUpdateForm,
        ticketUpdateFormPayload: orderUpdateData,
        ticketUpdateFormIsVisible,
        ticketUpdateFormIsLoading: ticketUpdateOneIsLoading,
        onMenuCreateOrderClick,
        onTicketTableRefresh,
        onTicketTableSearch,
        onTicketTableDetailClick,
        onTicketTableItemUpdateClick,
        onTicketTableItemDelete,
        onTicketDetailHide,
        onTicketDetailContactClick,
        onTicketDetailContactDetailHide,
        onTicketDetailProductClick,
        onTicketDetailProductTimelineHide,
        onTicketCreateFormHide,
        onTicketCreateFormFullyClosed,
        onTicketCreateFormInputFinish,
        onTicketCreateFormInputSubmit,
        onTicketCreateFormEndAnother,
        onTicketCreateFormEndFinish,
        onTicketUpdateFormHide,
        onTicketUpdateFormSubmit,
        onTicketUpdateFormFinish,
        onTicketUpdateFormFullyClosed,
    };
};
