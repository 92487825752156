import React from 'react';

import { Redirect, Route, Switch } from 'src/core/navigation/lib/route';

import Account from 'src/app/pages/account/account.page';
import Home from 'src/app/pages/home/home.page';
import Ticket from 'src/app/pages/ticket/ticket.page';

export default () => (
    <Switch>
        <Route exact={true} path='/' component={Home} />
        <Route exact={true} path='/account' component={Account} />
        <Route exact={true} path='/ticket' component={Ticket} />
        <Redirect to='/' />
    </Switch>
);
