import React from 'react';

import {
    Button,
    Descriptions,
    Divider,
    Drawer,
    PageHeader,
    Result,
    Skeleton,
    Space,
    Steps,
    Timeline,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PageBreadcrumb from 'src/app/component/page-breadcrumb.component';
import dateFormatter, { DATE_FORMAT } from 'src/app/helper/format/date';

import FormCreate from './ticket.form.create';
import FormUpdate from './ticket.form.update';
import usePresenter, { TicketCreateStep } from './ticket.presenter';
import TicketStatus from './ticket-status.component';
import TicketTable from './ticket.table';
import pageheader from './ticket.pageheader';

const MvcContract = () => {
    const {
        ticketTablePayload,
        ticketTableIsLoading,
        ticketTableActionIsDisabled,
        ticketDetailPayload,
        ticketDetailIsVisible,
        ticketDetailProductTimelineIsLoading,
        ticketDetailProductTimelineIsVisible,
        ticketDetailProductTimelinePayload,
        ticketDetailContactIsVisible,
        ticketCreateForm,
        ticketCreateFormStep,
        ticketCreateFormIsVisible,
        ticketCreateFormInputIsLoading,
        ticketCreateFormPreviewIsLoading,
        ticketCreateFormEndPayload,
        ticketUpdateForm,
        ticketUpdateFormIsLoading,
        ticketUpdateFormIsVisible,
        ticketUpdateFormPayload,
        onMenuCreateOrderClick,
        onTicketTableSearch,
        onTicketTableRefresh,
        onTicketTableDetailClick,
        onTicketTableItemDelete,
        onTicketTableItemUpdateClick,
        onTicketDetailHide,
        onTicketDetailContactClick,
        onTicketDetailContactDetailHide,
        onTicketDetailProductClick,
        onTicketDetailProductTimelineHide,
        onTicketCreateFormHide,
        onTicketCreateFormFullyClosed,
        onTicketCreateFormInputSubmit,
        onTicketCreateFormInputFinish,
        onTicketCreateFormEndAnother,
        onTicketCreateFormEndFinish,
        onTicketUpdateFormHide,
        onTicketUpdateFormSubmit,
        onTicketUpdateFormFinish,
        onTicketUpdateFormFullyClosed,
    } = usePresenter();

    return (
        <div>
            <PageBreadcrumb crumbs={pageheader.breadcrumbs} />
            <PageHeader
                onBack={pageheader.onBack}
                title='Case'
                extra={[
                    <Button
                        key='create'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={onMenuCreateOrderClick}
                    >
                        Create ticket
                    </Button>,
                ]}
            />

            <div style={{ padding: '0 24px' }}>
                <Space direction='vertical' size='large' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div />
                        <Space>
                            <Button onClick={onTicketTableRefresh}>Refresh</Button>
                        </Space>
                    </div>
                    <TicketTable
                        dataSource={ticketTablePayload}
                        isLoading={ticketTableIsLoading}
                        isActionDisabled={ticketTableActionIsDisabled}
                        onChange={onTicketTableSearch}
                        onClickDetail={onTicketTableDetailClick}
                    />
                </Space>
            </div>

            <Drawer
                width='75vw'
                visible={ticketCreateFormIsVisible}
                maskClosable={!ticketCreateFormPreviewIsLoading}
                afterVisibleChange={onTicketCreateFormFullyClosed}
                onClose={onTicketCreateFormHide}
                title='New tiket'
                headerStyle={{ display: 'none' }}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space>
                            {ticketCreateFormStep === TicketCreateStep.INPUT && (
                                <Button
                                    loading={ticketCreateFormInputIsLoading}
                                    onClick={onTicketCreateFormInputSubmit}
                                    type='primary'
                                    disabled={ticketCreateFormPreviewIsLoading}
                                >
                                    Submit
                                </Button>
                            )}
                        </Space>
                    </div>
                }
            >
                <Steps current={ticketCreateFormStep} size='small'>
                    <Steps.Step
                        title='Input'
                        icon={ticketCreateFormInputIsLoading ? <LoadingOutlined /> : undefined}
                    />
                    <Steps.Step title='Done' />
                </Steps>
                <Divider />
                {ticketCreateFormStep === TicketCreateStep.INPUT && (
                    <div style={{ width: '100%' }}>
                        <FormCreate
                            form={ticketCreateForm}
                            name='mvcContractForm'
                            onFinish={onTicketCreateFormInputFinish}
                            isLoading={ticketCreateFormInputIsLoading}
                            isDisabled={ticketCreateFormInputIsLoading}
                        />
                    </div>
                )}
                {ticketCreateFormStep === TicketCreateStep.END && (
                    <div style={{ width: '100%' }}>
                        <Result
                            status='success'
                            title='Ticket succesfully submitted'
                            subTitle={`Ticket number: ${ticketCreateFormEndPayload.id} please check for delivery or purchasing`}
                            extra={[
                                <Button
                                    type='primary'
                                    key='done'
                                    onClick={onTicketCreateFormEndFinish}
                                >
                                    Done
                                </Button>,
                                <Button key='again' onClick={onTicketCreateFormEndAnother}>
                                    Create Another
                                </Button>,
                            ]}
                        />
                        ,
                    </div>
                )}
            </Drawer>

            <Drawer
                width='65vw'
                visible={ticketDetailIsVisible}
                title='Ticket detail'
                onClose={onTicketDetailHide}
            >
                {!!ticketDetailPayload && (
                    <Space direction='vertical' size='large' style={{ width: '100%' }}>
                        <Descriptions size='small' bordered={true}>
                            <Descriptions.Item label='Id' span={2}>
                                {ticketDetailPayload.id}
                            </Descriptions.Item>
                            <Descriptions.Item label='Created At' span={1}>
                                {dateFormatter(ticketDetailPayload.createdAt).format(
                                    DATE_FORMAT['YYYY-MM-DD']
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label='Status' span={2}>
                                <TicketStatus status={ticketDetailPayload.status} />
                            </Descriptions.Item>
                            <Descriptions.Item label='Finished At' span={1}>
                                {ticketDetailPayload.inspectionFinishedAt
                                    ? dateFormatter(
                                          ticketDetailPayload.inspectionFinishedAt
                                      ).format(DATE_FORMAT['YYYY-MM-DD'])
                                    : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label='Serial Number' span={2}>
                                <Button
                                    type='link'
                                    style={{ padding: 0, height: 'auto' }}
                                    onClick={() => onTicketDetailProductClick(ticketDetailPayload)}
                                >
                                    {ticketDetailPayload.productId}
                                </Button>
                            </Descriptions.Item>
                            <Descriptions.Item label='Category' span={1}>
                                {ticketDetailPayload.productCategory}
                            </Descriptions.Item>
                            <Descriptions.Item label='Warranty' span={2}>
                                {ticketDetailPayload.productWarrantyStatus}
                            </Descriptions.Item>
                            <Descriptions.Item label='Control Status' span={1}>
                                {ticketDetailPayload.productControl}
                            </Descriptions.Item>
                            <Descriptions.Item label='Customer' span={3}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>{ticketDetailPayload.customerName}</span>
                                    <Button
                                        type='link'
                                        style={{ padding: 0, height: 'auto' }}
                                        onClick={() =>
                                            onTicketDetailContactClick(ticketDetailPayload)
                                        }
                                    >
                                        See Contact
                                    </Button>
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label='Note' span={3}>
                                {ticketDetailPayload.customerNote}
                            </Descriptions.Item>
                        </Descriptions>

                        <Descriptions size='small' bordered={true}>
                            <Descriptions.Item label='PIC' span={3}>
                                {ticketDetailPayload.inspectorName}
                            </Descriptions.Item>
                            <Descriptions.Item label='Service Center' span={3}>
                                <TicketStatus status={ticketDetailPayload.inspectionCenterName} />
                            </Descriptions.Item>
                            <Descriptions.Item label='Service Note' span={2}>
                                {ticketDetailPayload.inspectionNote}
                            </Descriptions.Item>
                        </Descriptions>

                        <Drawer
                            width='65vw'
                            visible={ticketDetailProductTimelineIsVisible}
                            title='Product Timeline'
                            onClose={onTicketDetailProductTimelineHide}
                        >
                            <Skeleton loading={ticketDetailProductTimelineIsLoading}>
                                {ticketDetailProductTimelinePayload && (
                                    <Timeline mode='left'>
                                        {ticketDetailProductTimelinePayload.map((timeline: any) => (
                                            <Timeline.Item
                                                color={
                                                    timeline.status === 'active' ? 'gray' : 'green'
                                                }
                                            >
                                                <Descriptions
                                                    size='small'
                                                    layout='horizontal'
                                                    colon={false}
                                                >
                                                    <Descriptions.Item
                                                        label='Submission Date'
                                                        span={3}
                                                    >
                                                        {dateFormatter(timeline.createdAt).format(
                                                            DATE_FORMAT['YYYY-MM-DD']
                                                        )}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label='ID' span={3}>
                                                        {timeline.id}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label='Notes' span={3}>
                                                        {timeline.customerNote}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label='Engineer' span={3}>
                                                        {timeline.inspectorName}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label='Result' span={3}>
                                                        {timeline.inspectionNote}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Timeline.Item>
                                        ))}
                                    </Timeline>
                                )}
                            </Skeleton>
                        </Drawer>
                    </Space>
                )}
            </Drawer>

            <Drawer
                width='65vw'
                visible={ticketUpdateFormIsVisible}
                afterVisibleChange={onTicketUpdateFormFullyClosed}
                title='Update ticket'
                onClose={onTicketUpdateFormHide}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space>
                            <Button
                                onClick={onTicketUpdateFormSubmit}
                                type='primary'
                                loading={ticketUpdateFormIsLoading}
                                disabled={ticketUpdateFormIsLoading}
                            >
                                Submit
                            </Button>
                        </Space>
                    </div>
                }
            >
                {!!ticketUpdateFormPayload && (
                    <Space direction='vertical' size='large' style={{ width: '100%' }}>
                        {ticketUpdateFormIsVisible && (
                            <FormUpdate
                                form={ticketUpdateForm}
                                name='ticketUpdateForm'
                                onFinish={onTicketUpdateFormFinish}
                                isDisabled={ticketUpdateFormIsLoading}
                            />
                        )}
                    </Space>
                )}
            </Drawer>
        </div>
    );
};

export default MvcContract;
