import React, { createContext, useContext, useState } from 'react';

import { Avatar, Button, Divider, Layout, Menu, Space } from 'antd';
import { AlertOutlined, SettingOutlined } from '@ant-design/icons';

import { useAuthN } from 'src/core/auth/auth-n';
import Link from 'src/core/navigation/lib/link';

import { ReactComponent as Logo } from './assets/app.logo.color.svg';

const MENU = [
    {
        key: '/ticket',
        label: 'Case',
        href: '/ticket',
        icon: AlertOutlined,
    },
];

const LayoutContext = createContext({
    isMenuCollapsed: false,
    setIsMenuCollapsed: (x: boolean) => null,
});

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider: React.FC = ({ children }) => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    return (
        <LayoutContext.Provider
            value={{ isMenuCollapsed, setIsMenuCollapsed: setIsMenuCollapsed as any }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

const AppLayout: React.FC<{ content: any }> = ({ content }) => {
    const { isMenuCollapsed } = useLayout();
    const { user } = useAuthN();
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout.Sider
                collapsedWidth={0}
                collapsed={isMenuCollapsed}
                theme='light'
                style={{ background: '#fff', minHeight: '100vh', borderRight: '1px solid #f0f0f0' }}
            >
                <Menu mode='inline' style={{ borderRight: 0 }} selectedKeys={[]}>
                    <div
                        style={{
                            fontWeight: 900,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            padding: '8px 24px',
                            height: '55px',
                            boxShadow: '#f0f0f0 0px -1px 0px 0px inset',
                        }}
                    >
                        <span
                            style={{
                                fontSize: '1.25rem',
                                letterSpacing: '-.025em',
                                fontWeight: 700,
                            }}
                        >
                            Primatech
                        </span>
                        <svg
                            style={{
                                width: 32,
                                height: 32,
                                color: '#6c63fe',
                                marginLeft: -4,
                                marginTop: -8,
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='#ff6484'
                        >
                            <path
                                fill-rule='evenodd'
                                d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
                                clip-rule='evenodd'
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px',
                            justifyContent: 'space-between',
                            boxShadow: '#f0f0f0 0px -1px 0px 0px inset',
                        }}
                    >
                        <div>
                            <Avatar style={{ margin: '0 8px' }} size='small' src={user?.picture} />
                            {user?.nickname}
                        </div>
                        <Space size='small'>
                            <Divider type='vertical' />
                            <Link href='/account'>
                                <Button type='link' size='small' icon={<SettingOutlined />} />
                            </Link>
                        </Space>
                    </div>
                    {MENU.map((menu) => (
                        <Menu.Item key={menu.key}>
                            <Link href={menu.href}>
                                <Button
                                    type='link'
                                    icon={<menu.icon />}
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        padding: '4px 0',
                                        height: '100%',
                                    }}
                                >
                                    {menu.label}
                                </Button>
                            </Link>
                        </Menu.Item>
                    ))}
                </Menu>
            </Layout.Sider>
            <Layout>
                <Layout.Content style={{ background: '#fff' }}>{content}</Layout.Content>
            </Layout>
        </Layout>
    );
};

export default AppLayout;
