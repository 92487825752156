import React from 'react';

import { AutoComplete, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';

import { ContactAutocomplete } from 'src/app/container/form/contact-autocomplete.component';

import dateFormatter, { DATE_FORMAT } from 'src/app/helper/format/date';

const TicketUpdateForm: React.FC<{
    form?: any;
    onFinish?: any;
    name: string;
    isDisabled?: boolean;
    isLoading?: boolean;
}> = ({ form, onFinish, name, isDisabled }) => {
    const STRUCTURE = {
        createdAt: {
            dataIndex: 'createdAt',
            label: 'Case Date',
            placeholder: 'Date',
        },
        ticketIdPrefix: {
            dataIndex: 'ticketIdPrefix',
        },
        ticketId: {
            dataIndex: 'ticketId',
            label: 'Case id',
            placeholder: 'Internal identifier',
        },
        ticketIdSwitch: {
            dataIndex: 'ticketIdSwitch',
            label: 'Case id',
            placeholder: 'Internal identifier',
        },
        salePerson: {
            dataIndex: 'salePerson',
            label: 'Sale Person',
            placeholder: 'Input sale person',
        },
        customer: {
            dataIndex: 'customer',
            label: 'Customer',
            placeholder: 'Input customer',
        },
        customerReferenceId: {
            dataIndex: 'customerReferenceId',
            label: 'Customer Reference Id',
            placeholder: 'ie. purchase order number',
        },
        customerNote: {
            dataIndex: 'customerNote',
            label: 'Complaint Note',
            placeholder: 'Additonal information',
        },
        productCategory: {
            dataIndex: 'productCategory',
            label: 'Product Category',
            placeholder: 'Category',
        },
        productId: {
            dataIndex: 'productId',
            label: 'Product SN',
            placeholder: 'Serial number',
        },
        productWarrantyStatus: {
            dataIndex: 'productWarrantyStatus',
            label: 'Warranty',
            placeholder: 'Status',
        },
        productControl: {
            dataIndex: 'productControl',
            label: 'Control',
            placeholder: 'Status',
        },
    };

    return (
        <Form form={form} layout='vertical' onFinish={onFinish} name={name} hideRequiredMark={true}>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        label={STRUCTURE.productId.label}
                        name={STRUCTURE.productId.dataIndex}
                        rules={[
                            {
                                required: true,
                                message: 'Missing serial number',
                            },
                        ]}
                    >
                        <Input
                            placeholder={STRUCTURE.productId.placeholder}
                            allowClear={true}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={STRUCTURE.productCategory.label}
                        name={STRUCTURE.productCategory.dataIndex}
                        rules={[
                            {
                                required: true,
                                message: 'Missing category',
                            },
                        ]}
                    >
                        <AutoComplete
                            style={{ width: '100%' }}
                            options={[
                                { value: 'pc' },
                                { value: 'notebook' },
                                { value: 'printer' },
                                { value: 'scanner' },
                                { value: 'ups' },
                                { value: 'cisco' },
                                { value: 'iphone' },
                                { value: 'pc accessory' },
                                { value: 'notebook accessory' },
                            ]}
                            placeholder={STRUCTURE.productCategory.placeholder}
                            filterOption={(inputValue, option) =>
                                option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            }
                            allowClear={true}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={5} offset={7}>
                    <Form.Item
                        label={STRUCTURE.createdAt.label}
                        name={STRUCTURE.createdAt.dataIndex}
                        initialValue={dateFormatter()}
                        rules={[{ required: true, message: 'Missing date' }]}
                    >
                        <DatePicker style={{ width: '100%' }} disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        label={STRUCTURE.productWarrantyStatus.label}
                        name={STRUCTURE.productWarrantyStatus.dataIndex}
                        rules={[
                            {
                                required: true,
                                message: 'Missing warrant status',
                            },
                        ]}
                    >
                        <Select
                            disabled={true}
                            placeholder={STRUCTURE.productWarrantyStatus.placeholder}
                            options={[
                                {
                                    label: 'Active',
                                    value: 'active',
                                },
                                {
                                    label: 'Inactive',
                                    value: 'inactive',
                                },
                                {
                                    label: 'Unknown',
                                    value: 'unknown',
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={STRUCTURE.productControl.label}
                        name={STRUCTURE.productControl.dataIndex}
                        rules={[
                            {
                                required: true,
                                message: 'Missing warrant status',
                            },
                        ]}
                    >
                        <Radio.Group disabled={true}>
                            <Radio value='offsite'>On-Site</Radio>
                            <Radio value='onsite'>Off-Site</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={STRUCTURE.customerNote.label}
                        name={STRUCTURE.customerNote.dataIndex}
                    >
                        <Input.TextArea
                            rows={3}
                            form={form}
                            placeholder={STRUCTURE.customerNote.placeholder}
                            allowClear={true}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default TicketUpdateForm;
